const $forms =  document.querySelectorAll(".form--compact, .gform_wrapper");


$forms.forEach(($form, i) => {
	const $inputs = $form.querySelectorAll('input, select, textarea');

	const $textInputs = $form.querySelectorAll(".ginput_container_text, .ginput_container_textarea, .ginput_container_select, .ginput_container_number, .ginput_container_date, .ginput_container_phone, .ginput_container_website, .ginput_container_email");

	const $selects = $form.querySelectorAll("select:not([multiple])");

	$inputs.forEach(($input, i) => {
		$input.addEventListener('blur', function() {
			if($input.type!='submit') {
				$input.closest('.form__field, .gfield, .ginput_complex > span').classList.remove('has-focus');
				checkForValue($input);
			}
		});

		$input.addEventListener('focus', function() {
			if($input.type!='submit') {
				$input.closest('.form__field, .gfield, .ginput_complex > span').classList.add('has-focus');
			}
		});
	});

	$textInputs.forEach(($textInput, i) => {
		$textInput.closest('.gfield').classList.add('gfield_text_input');
	});

	$selects.forEach(($select, i) => {
		var $option = document.createElement("option");
		$option.setAttribute('value', '');
		$option.setAttribute('selected', 'selected');

		var $firstOption = $select.firstElementChild.textContent;

		if($firstOption != '') {
			$select.prepend($option);
		}
		$select.parentNode.classList.add('gfield_select_input');
	});
});

var allInputs = document.querySelectorAll('input, textarea');

var AUTOFILLED = 'is-autofilled';

var checkForValue = function(input) {
	if(input.type!='submit' && input.type!='hidden') {
		const field = input.closest('.form__field, .gfield, .ginput_complex > span, .ginput_container_time');

		if (field) {
			if (input.value != '') {
				field.classList.add('has-value');
			} else {
				field.classList.remove('has-value');
			}
		} else {
			return false;
		}
	}
};

var onAnimationStart = function({target, animationName}) {
	switch (animationName) {
		case 'onAutoFillStart':
			console.log('start');
			return target.parentNode.classList.add(AUTOFILLED);
		case 'onAutoFillCancel':
			console.log('cancel');
			return target.parentNode.classList.remove(AUTOFILLED);
	}
};
allInputs.forEach(function(e){
	e.addEventListener('animationstart', onAnimationStart, false);
	checkForValue(e);
});

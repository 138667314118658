// NOTE: See https://web.dev/external-anchors-use-rel-noopener/?utm_source=lighthouse&utm_medium=devtools
// Links with `target="_blank"` can experience security and performance issues
// without also adding `rel="noopener"` or `rel="noreferrer"`.

// This scipts adds them to every link with `target="_blank"`.
// It will add `rel-"noopener"` to internal links and `rel="noreferrer"` to external links.

const $targettedLinks = document.querySelectorAll("[target='_blank']");

$targettedLinks.forEach(($link, i) => {

	function isExternal(link) {
    	return link.hostname !== window.location.hostname;
	}

	if (isExternal($link)) {
		$link.setAttribute('rel', 'noreferrer');
	} else {
		$link.setAttribute('rel', 'noopener');
	}
});
